.banner__dialog {
  background-color: #ffff;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #ededed;
}
.banner__container {
  margin: auto;
  display: flex;
  gap: 0.7rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-height: 50rem;
  overflow-y: scroll;
}
.cookie__banner__title {
  font-size: 1.2rem;
  font-weight: 600;
}
.cookie__banner__text a {
  text-decoration: underline !important;
}

.cookie__config_cookies {
  background-color: var(--color-dark);
  margin-right: 20px;
}

.cookie__accept_cookies {
  background-color: var(--color-dark);
}

.banner__buttoncolgroup{
  display: flex;
  gap: 2rem;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.banner__buttongroup{
  display: flex;
  gap: 2rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.banner__switch_group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media (max-width: 768px) {
  .banner__dialog {
    max-height: 85%;
    font-size: 1rem;
  }

  .banner__container {
    display: flex;
    width: 90%;
    gap: 1rem;
    overflow-y: scroll;
    max-height: 30rem;
  }
  .banner__switch_group {
    flex-direction: column;
  }
  .banner__buttongroup{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .banner__buttoncolgroup{
    display: flex;
    gap: 5px;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}
