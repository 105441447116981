:root {
  --main-color-synblue: #0e046d;
  --main-color-syngreen: #c5fd00;
  --main-color-syngreen-dark: #a3d600;
  --main-color-synred: #e47272;
  --main-color-synpurple: #e2a8cd;
  --main-color-synlight: #18e3e3;
  --main-color-medium: #92949c;
  --main-color-light: #d5d5d5;
  --color-dark: #424242;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Encode Sans, Roboto Slab, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.glass {
  background: rgba(255, 255, 255, 0.397);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.9px);
  -webkit-backdrop-filter: blur(8.9px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.subtitle1 {
  color: var(--main-color-synblue);
  text-transform: uppercase;
  font-weight: 600;
}

.subtitle2 {
  color: var(--main-color-medium);
  text-transform: uppercase;
  font-weight: 600;
}

.body2 {
  color: var(--main-color-medium);
  font-size: 1.1rem;
}

.form-field {
  border: none;
  border-bottom: 1px solid var(--main-color-medium);
}
